import { useState } from 'react';

const SandwichSite = () => {
  // ��������� ��� �������� ������
  const [clickCount, setClickCount] = useState(0);

  // ���������� ������
  const handleImageClick = () => {
    if (clickCount < 4) {
      setClickCount(clickCount + 1); // ����������� ������� �� 1
      console.log("���������� ������:", clickCount + 1);
    } else {
      setClickCount(5); // ������������� ������� �� 5 ����� 4 ������
      console.log("����������� ������ ���������!");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {clickCount >= 5 ? (
        // ����� 5 ������ � ������ ����������� �� "222"
        <img
          src="/222.jpg"
          alt="Second Image"
          className="w-64 h-64 object-cover rounded-lg"
        />
      ) : (
        // �� 5 ������ � ���������� ����������� "111"
        <img
          src="/111.jpg"
          alt="First Image"
          className="w-64 h-64 object-cover rounded-lg cursor-pointer"
          onClick={handleImageClick}
        />
      )}
    </div>
  );
};

export default SandwichSite;